<template>
  <v-card>
    <div class="px-6">
      <v-card-title class="d-flex justify-center mb-4">
        <v-spacer />

        <span>Estornar comissão</span>

        <v-spacer />

        <v-icon
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <Separator label="Estornar" />

      <div class="container-info">
        <div class="container-icon">
          <v-icon
            size="20"
            class=""
            color="white"
          >
            {{ icons.mdiInformationVariant }}
          </v-icon>
        </div>

        <div class="container-text">
          <span>Esta ação deve ser acionada quando o cliente compra uma cota de um modelo e decide mudar para outro, permitindo o estorno da comissão paga do veículo anterior ao vendedor.</span>
        </div>
      </div>

      <v-form
        ref="form"
        class="mt-4"
      >
        <p>
          Insira o número da proposta
          <strong style="color: rgba(239, 112, 255, 1)">
            anterior
          </strong>
        </p>
        <v-text-field
          v-model="oldProposal"
          :rules="[rules.required]"
          label="N° da proprosta"
          outlined
          dense
        ></v-text-field>

        <p>
          Insira o número da
          <strong style="color: rgba(239, 112, 255, 1)">
            proposta atual
          </strong>
        </p>
        <v-text-field
          v-model="currentProposal"
          :rules="[rules.required]"
          label="N° da proprosta"
          outlined
          dense
        ></v-text-field>

        <p>
          Insira o percentual da proposta anterior
        </p>
        <v-text-field
          v-model="oldPercentage"
          :rules="[rules.required]"
          label="Percentual"
          outlined
          dense
        ></v-text-field>

        <div class="container-action">
          <v-btn
            color="info"
            class="ml-5"
            @click="restoreCommission"
          >
            <div v-if="!loadingCircle">
              <span>ESTORNAR</span>

              <v-icon
                size="20"
                class="ml-2 "
                color="white"
              >
                {{ icons.mdiRestore }}
              </v-icon>
            </div>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </div>
      </v-form>

      <v-card-text>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
import Separator from '@/components/Separator.vue'
import storeMixin from '@/plugins/axiosMixins/storeMixin'
import myMixin from '@/plugins/mixin'
import messages from '@/plugins/showMessageMixin'
import {
  mdiClose, mdiFilterVariantRemove, mdiInformationVariant, mdiRestore,
} from '@mdi/js'

export default {
  components: {
    Separator,
  },
  mixins: [myMixin, messages, storeMixin],

  data() {
    return {
      loadingCircle: false,
      rules: {
        required: value => !!value || 'Campo obrigatório.',
      },
      isLoading: false,
      icons: {
        mdiClose,
        mdiFilterVariantRemove,
        mdiInformationVariant,
        mdiRestore,
      },
      currentProposal: '',
      oldProposal: '',
      oldPercentage: '',
    }
  },

  methods: {
    async restoreCommission() {
      const formIsValid = this.$refs.form.validate()
      const endpoint = '/api/v1/integration/contract/cancel_proposal'

      const body = {
        proposal_number_old: String(this.oldProposal),
        porcent_proposal_old: Number(this.oldPercentage),
        proposal_number_current: String(this.currentProposal),
      }

      if (formIsValid) {
        this.loadingCircle = true

        await this.postRegister(endpoint, body)

        this.loadingCircle = false

        return
      }

      this.showMessage({
        title: 'Campos vazios!',
        text: 'Verifique se todos os campos estão preenchidos',
      })
    },
  },
}
</script>
<style scoped>
.container-info{
  width: 100%;
  background: #1E1E1E4D;
  border-radius: 5px;
  margin-top: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 10px;
}

.container-icon{
  background: #16B1FF;
  border-radius: 5px;
  height: 30px;
  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.container-text{
  width: 90%;
  font-size: 13px;
  text-align: justify;
}
.container-action{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
