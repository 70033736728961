import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":""}},[_c(VCardText,{staticClass:"d-flex"},[_c(VRow,{staticClass:"mt-1"},[_c('div',{staticClass:"ml-4 d-flex flex-column"},[_c('span',{staticClass:"mb-4"},[_vm._v("Comissões CNH")]),(_vm.itemsTable.length > 0)?_c('div',{staticClass:"d-flex items-center mb-2"},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarAccountOutline)+" ")]),_c('span',[_vm._v("REFERÊNCIA: "+_vm._s(_vm.computedFormatDateReference))])],1):_vm._e()]),_c(VSpacer),_c(VTextField,{staticClass:"mr-2",attrs:{"label":"Buscar por nome","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findFilterResult()}},model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c(VBtn,{staticClass:"mr-4 mb-4",attrs:{"color":_vm.itemsTable.length > 0 ? 'success' : 'gray',"outlined":""},on:{"click":function($event){_vm.modalFilter = true}}},[_c(VIcon,{attrs:{"size":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Filtro")])],1),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.itemsTable.length > 0),expression:"itemsTable.length > 0"}],staticClass:"mr-4 mb-4",attrs:{"color":"error","outlined":""},on:{"click":_vm.clearGrid}},[_c(VIcon,{attrs:{"size":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariantRemove)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Limpar")])],1),(_vm.isLoadingExport)?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"warning","outlined":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"gray","size":25}})],1):_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"warning","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1)]}}])},[_c(VList,_vm._l((_vm.itemsListExport),function(item){return _c(VListItem,{key:item,attrs:{"link":""}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.exportCsv(item)}}})],1)}),1)],1)],1)],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.isLoadingTableData,"loading-text":"Carregando dados...","disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"container-vacation"},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.vacation),expression:"item.vacation"}],staticClass:"vacation"},[_vm._v(" "+_vm._s(item.vacation ? 'Férias' : '')+" ")])])]}},{key:"item.controlling_status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",staticStyle:{"font-size":"12px"},attrs:{"text":"","color":item.controlling_status === 'VALIDADO'
            ? 'success'
            : item.controlling_status === 'REGISTRADO'
              ? 'primary'
              : 'error'}},[_vm._v(" "+_vm._s(item.controlling_status)+" ")])]}},{key:"item.self",fn:function(ref){
              var item = ref.item;
return [_vm._v(" #"+_vm._s(item.self)+" ")]}},{key:"item.valor_vehicles",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.valor_vehicles ? Number(item.valor_vehicles).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.commission",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.commission ? Number(item.commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(item.reference === _vm.loadingCircle ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.detailsItems(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]):_vm._e(),(item.reference === _vm.loadingCircle ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()]}},{key:"no-data",fn:function(){return [_c('NoDataInterface')]},proxy:true}],null,true),model:{value:(_vm.itemTableSelected),callback:function ($$v) {_vm.itemTableSelected=$$v},expression:"itemTableSelected"}}),_c(VDialog,{attrs:{"max-width":"1600px"},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('Details',{key:_vm.vendor_name,attrs:{"reference":_vm.reference,"vendor-name":_vm.vendor_name,"status-sales":_vm.statusSales,"vendor-cpf":_vm.vendor_cpf,"vacation-info":_vm.vacation,"commission":_vm.commission,"total-sales":_vm.totalSales,"number-sales":_vm.numberSales},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":_vm.closeModal}})],1),_c(VDialog,{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.isLoadingValidate),callback:function ($$v) {_vm.isLoadingValidate=$$v},expression:"isLoadingValidate"}},[_c(VCard,{attrs:{"color":"purple","dark":""}},[_c(VCardText,[_vm._v(" Aguarde, estamos validando "),_c(VProgressLinear,{staticClass:"mb-0 mt-2",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.modalFilter),callback:function ($$v) {_vm.modalFilter=$$v},expression:"modalFilter"}},[_c('FilterModal',{on:{"close":function($event){_vm.modalFilter = false},"filteredData":function ($event) { return _vm.setTableData($event); },"filteredBody":function ($event) { return _vm.setBodyFilter($event); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }