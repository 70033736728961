<template>
  <v-card>
    <v-card-title> Relatório de Inconsistência </v-card-title>
    <v-card-text>
      <v-autocomplete
        v-model="leaderName"
        :items="leaderList"
        item-text="name"
        item-value="id"
        :prepend-inner-icon="icons.mdiAccountOutline"
        label="Nome do responsável"
        outlined
        dense
      ></v-autocomplete>

      <vue-editor v-model="descriptionReport"></vue-editor>

      <div class="mt-5 d-flex justify-end">
        <v-btn
          color="info"
          class="mr-2"
          @click="sendInconsistencyReport"
        >
          Emitir
        </v-btn>
        <v-btn
          color="error"
          @click="closeModal"
        >
          Fechar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline, mdiCellphone, mdiEmailOutline, mdiLockOutline,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },
  props: {
    idsSales: {
      type: Array,
      required: true,
    },

    updatedTable: { type: Function, default: () => {} },
  },
  data() {
    return {
      leaderName: '',
      leaderList: [],
      descriptionReport: '',
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCellphone,
        mdiLockOutline,
      },
    }
  },

  created() {
    this.leaderList = localStorageSlim.get('leaderList', { decrypt: true })
  },

  methods: {
    async sendInconsistencyReport() {
      const ids = []

      this.idsSales.map(item => {
        ids.push(item.id)
      })

      const body = {
        for_users_id: this.leaderName,
        text: this.descriptionReport,
        contracts_id: ids,
      }

      await axiosIns
        .post('/api/v1/integration/occurrence_ri/store', body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'RI gerado com sucesso!',
            showConfirmButton: false,
            timer: 2500,
          })

          this.updatedTable()
          this.closeModal()
          this.clearData()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao emitir RI!',
            showConfirmButton: false,
            text: error,
            timer: 2500,
          })
        })

      const bodyStatus = {
        controlling_status: 'INCONSISTENTE',
        contracts_id: ids,
      }

      await axiosIns
        .post('/api/v1/integration/contract/update_status', bodyStatus)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Status modificado',
            showConfirmButton: false,
            timer: 4000,
          })
          this.updatedTable()
          this.closeModal()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar vendas!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })
    },

    clearData() {
      this.vendorName = ''
      this.descriptionReport = ''
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
