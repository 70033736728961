<template>
  <div
    style="height: 658px; font-weight: 500; gap: 56px;"
    class="d-flex align-center justify-center flex-column"
  >
    <div class="d-flex flex-column">
      <span
        :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
      >Nenhum registro encontrado!</span>
      <span
        :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
      >Por favor, verifique os filtros de pesquisa.</span>
    </div>
    <img
      :src="computedSrc"
      alt="Imagem de um bonequinho com uma lupa procurando por dados em um servidor"
    >
  </div>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import noDataImageLight from '@/assets/images/svg/no-data-logs-light.svg'
import noDataImageDark from '@/assets/images/svg/no-data-logs.svg'

export default {
  name: 'NoDataInterface',
  data() {
    return {
      noDataImageDark,
      noDataImageLight,
    }
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
    computedSrc() {
      return this.isDark ? noDataImageDark : noDataImageLight
    },
  },
}
</script>

<style scoped>
  .dark-mode {
    color: #e7e3fc;
    font-size: 14px;
    font-weight: 500
  }
  .light-mode {
    color: #312d4b;
    font-size: 14px;
    font-weight: 500
  }
</style>
