import axiosIns from '../axios'

const storeMixin = {
  methods: {
    // TODO - Adicionar métodos para store

    /**
     * Envia registros a partir de um endpoint e body registro usando método POST
     *
     * @param {string} endpoint - url da API
     * @param {Object.<string, string | number>} body
     * @returns {void}
     */
    async postRegister(endpoint, body) {
      const confirmed = await this.confirmPost()
      if (!confirmed) {
        this.showCancelledMessage()

        return
      }

      try {
        await this.postRecord(endpoint, body)
        this.showSuccessMessage()
        this.updatedTable()
      } catch (error) {
        this.showErrorMessage()
      } finally {
        this.closeModal()
      }
    },

    async confirmPost() {
      const result = await this.$swal({
        icon: 'warning',
        title: 'Deseja enviar o registro?',
        text: 'A operação é irreversível, tem certeza?',
        showCancelButton: true,
        confirmButtonText: 'Sim, enviar!',
        cancelButtonText: 'Não, cancelar!',
      })

      return result.isConfirmed
    },

    /**
     *
     * @param {string} endpoint
     * @param {Object.<string, string | number>} body
     */
    async postRecord(endpoint, body) {
      await axiosIns.post(endpoint, body)
    },

    showSuccessMessage() {
      this.$swal({
        icon: 'success',
        title: 'Registro enviado!',
        text: 'Feito com sucesso!',
        timer: 2000,
      })
    },

    showCancelledMessage() {
      this.$swal({
        icon: 'error',
        title: 'Cancelado!',
        text: 'Operação cancelada',
        timer: 2000,
      })
    },

    showErrorMessage() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao enviar registro!',
        text: 'Você enviar este registro, Tente editar!',
      })
    },

    updatedTable() {
      this.$emit('updatedTable')
    },

    closeModal() {
      this.$emit('close')
    },
  },
}

export default storeMixin
